// styles
import './Typography.css';

function Typography({ children, classes, component, href, mods }) {
	const Tag = component ? component : 'p';
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames =
		mods && mods.length ? ` ${mods.map((mod) => `typography--${mod}`).join(' ')}` : '';
	const typographyProps = {};

	if (href) {
		typographyProps.href = href;
	}

	return (
		<Tag className={`typography${classNames}${modNames}`} {...typographyProps}>
			{children}
		</Tag>
	);
}

export default Typography;
