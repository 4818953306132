// styles
import './Tooltip.css';

const Tooltip = ({ children, title }) => {
	return (
		<div className="tooltip" title={title}>
			{children}
		</div>
	);
};

export default Tooltip;
