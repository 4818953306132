import React from 'react';

// core
import Typography from 'components/ui/Typography/Typography';

// icons
import IconCheckCircleOutline from '@material-ui/icons/CheckCircleOutline';

// styles
import './Alert.css';

const Alert = ({ children, classes, icon, mods, text, ...other }) => {
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames = mods ? ` ${mods.map((mod) => `alert--${mod}`).join(' ')}` : '';
	const alertProps = {
		className: `alert${classNames}${modNames}`,
	};

	const renderIcon = () => {
		if (icon) {
			return icon;
		}

		if (mods.includes('success')) {
			return <IconCheckCircleOutline />;
		}

		return false;
	};

	const renderText = () => {
		if (children) {
			return children;
		}

		return (
			<>
				{renderIcon()}
				<Typography component="span">{text}</Typography>
			</>
		);
	};

	return (
		<div {...alertProps}>
			<div className="alert__text">{renderText()}</div>
		</div>
	);
};

export default Alert;
