import React from 'react';

// core
import Typography from 'components/ui/Typography/Typography';

// styles
import './Button.css';

const Button = ({
	children,
	classes,
	component,
	href,
	icon,
	mods,
	onClick,
	text,
	to,
	...other
}) => {
	let Tag = component ? component : 'button';

	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames = mods ? ` ${mods.map((mod) => `btn--${mod}`).join(' ')}` : '';
	const btnProps = {
		className: `btn${classNames}${modNames}`,
	};

	const renderText = () => {
		if (icon) {
			return icon;
		}

		return (
			<Typography classes={['btn__text']} component="span">
				{children ? children : text}
			</Typography>
		);
	};

	if (href || to) {
		Tag = 'a';
	}

	if (href) {
		btnProps.href = href;
	}

	if (onClick) {
		btnProps.onClick = onClick;
	}

	if (to) {
		btnProps.to = to;
	}

	return (
		<Tag {...btnProps} {...other}>
			{renderText()}
		</Tag>
	);
};

export default Button;
