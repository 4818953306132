import Typography from 'components/ui/Typography/Typography';

// styles
import './Params.css';

const Params = ({ params }) => {
	const renderParams = () => {
		return params.map((param, key) => {
			return (
				<li className="params__item" key={key}>
					<div className="item__header">
						<Typography classes={['item__param']} component="strong">
							{param.param}
						</Typography>
						{'required' in param && (
							<Typography component="em">{param.required ? 'required' : 'optional'}</Typography>
						)}
						{'type' in param && <Typography component="span">{param.type}</Typography>}
					</div>
					<Typography classes={['item__description']} component="div">
						{param.description}
					</Typography>
				</li>
			);
		});
	};

	if (!params) {
		return false;
	}

	return <ul className="params">{renderParams()}</ul>;
};

export default Params;
