// ui
import Typography from 'components/ui/Typography/Typography';

// styles
import './SwatchCard.css';

function SwatchCard({ classes, color, component, href, id, mods, ref, title }) {
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames =
		mods && mods.length ? ` ${mods.map((mod) => `typography--${mod}`).join(' ')}` : '';
	const swatchcardProps = {};

	if (id) {
		swatchcardProps.id = id;
	}

	if (ref) {
		swatchcardProps.ref = ref;
	}

	return (
		<div className={`swatchcard${classNames}${modNames}`} {...swatchcardProps}>
			<div className="swatchcard__color" style={{ backgroundColor: color }}></div>
			<div className="swatchcard__copy">
				<Typography classes={['swatchcard__title']}>{title}</Typography>
				<Typography>{color}</Typography>
			</div>
		</div>
	);
}

export default SwatchCard;
