import React, { useEffect } from 'react';

// layout
import Section from 'components/layout/Section/Section';
import Wrap from 'components/layout/Wrap/Wrap';

// ui
import Button from 'components/ui/Button/Button';
import SwatchCard from 'components/ui/SwatchCard/SwatchCard';
import Typography from 'components/ui/Typography/Typography';

// styles
import './Hero.css';

const Hero = () => {
	useEffect(() => {
		const swatches = document.getElementsByClassName('hero__swatch');
		for (let i = 0; i < swatches.length; i++) {
			swatches[i].classList.add('hero__swatch--active');
		}
	}, []);

	return (
		<Section classes={['hero']} mods={['vh', 'vcenter']}>
			<Wrap classes={['hero__wrap']}>
				<div className="hero__intro">
					<div>
						<Typography component="h1">{process.env.REACT_APP_TITLE}</Typography>
						<Typography classes={['hero__copy']}>
							Palett is a very simple color API used to convert colors into various formats and
							generate color schemes.
						</Typography>
						<div className="hero__btns">
							<Button href="#intro" mods={['pink-500']}>
								Get Started
							</Button>
						</div>
					</div>
				</div>

				<div className="hero__image">
					<SwatchCard classes={['hero__swatch']} color="#B2EBF2" title="Blizzard" />
					<SwatchCard classes={['hero__swatch']} color="#4DB6AC" title="Fountain" />
					<SwatchCard classes={['hero__swatch']} color="#039BE5" title="Cerulean" />
					<SwatchCard classes={['hero__swatch']} color="#ffcc99" title="Peach" />
				</div>
			</Wrap>
		</Section>
	);
};

export default Hero;
