import React, { useEffect, useCallback } from 'react';

// highlight
import hljs from 'highlight.js';

// ui
import Button from 'components/ui/Button/Button';
import Params from 'components/ui/Params/Params';
import Swatch from 'components/ui/Swatch/Swatch';
import Tooltip from 'components/ui/Tooltip/Tooltip';
import Typography from 'components/ui/Typography/Typography';
import UrlBar from 'components/ui/UrlBar/UrlBar';

// icons
import IconArrowForward from '@material-ui/icons/ArrowForward';

// styles
import './Endpoint.css';

const Endpoint = ({
	buttons,
	classes,
	example,
	id,
	intro,
	mods,
	paramsPath,
	paramsResponse,
	paramsQuery,
	path,
	response,
	title,
	type,
	url,
}) => {
	// vars
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames = mods ? ` ${mods.map((mod) => `btn--${mod}`).join(' ')}` : '';

	const renderButtons = useCallback(() => {
		if (!buttons) {
			return false;
		}

		const listItems = buttons.map((btn, key) => {
			const btnMods = ['sm', 'orange-500', 'border'];

			let btnUrl = url;

			if (btn?.params?.model) {
				btnUrl += `/${btn.params.model}`;
			}

			if (btn?.params?.value) {
				btnUrl += `/${btn.params.value}`;
			}

			if (btn?.params?.percentage) {
				btnUrl += `/${btn.params.percentage}`;
			}

			return (
				<li key={`endpoint-btn-${key}`}>
					<Button component="a" href={btnUrl} mods={btnMods} target="_blank">
						{btn.text}
					</Button>
				</li>
			);
		});

		return <ul className="endpoint__btns">{listItems}</ul>;
	}, [buttons, url]);

	const renderSwatch = ({ hex, label, tooltip }) => {
		return (
			<div className="grid__item">
				<Tooltip title={tooltip}>
					<Swatch hex={hex} />
				</Tooltip>
				{label && <div className="item__label">{label}</div>}
			</div>
		);
	};

	const renderSwatches = () => {
		if (!response?.colors?.length && !response?.color) {
			return false;
		}

		if (response?.color) {
			return renderSwatch({
				hex: response.color.hex,
				tooltip: response.color.name,
			});
		}

		return response.colors.map((color, key) => {
			return renderSwatch({
				hex: color.hex,
				tooltip: color.name,
			});
		});
	};

	useEffect(() => {
		hljs.highlightAll();
	}, []);

	const btnHex = buttons.find((btn) => btn.text === 'Hex');
	let exampleUrl = `${url}/hex/${btnHex.params.value}`;

	if (btnHex.params.percentage) {
		exampleUrl += `/${btnHex.params.percentage}`;
	}

	return (
		<div className={`endpoint${classNames}${modNames}`} id={id}>
			<div className="endpoint__section">
				<Typography classes={['endpoint__title']} component="h3">
					{title}
				</Typography>
				{intro && <Typography classes={['endpoint__content']}>{intro}</Typography>}
				<UrlBar classes={['endpoint__urlbar']} type={type} path={`${path}`} />
				{renderButtons()}
			</div>

			{paramsResponse && (
				<div className="endpoint__params endpoint__section">
					<Typography component="h4">Response</Typography>
					<Params params={paramsResponse} />
				</div>
			)}

			<div className="endpoint__section">
				<figure className="endpoint__response">
					<figcaption hidden>
						<Typography classes={['endpoint__subtitle', 'h4']} component="h3">
							Example
						</Typography>
					</figcaption>
					<pre>
						<code className="hljs json">{JSON.stringify(response, undefined, 2)}</code>
					</pre>
				</figure>
			</div>

			{example && (
				<div className="endpoint__example">
					<div className="example__header">
						<Typography classes={['example__subtitle', 'h4']} component="h3">
							Example
						</Typography>
						<Button href={exampleUrl} mods={['text']} target="_blank">
							{exampleUrl}
						</Button>
					</div>
					<div className="example__content">
						<div className="content__grid">
							<div className="grid__item">
								<Tooltip title={example.input.name}>
									<Swatch hex={example.input.hex} />
								</Tooltip>
							</div>
							<div className="grid__item">
								<div className="item__flex">
									<IconArrowForward />
								</div>
							</div>
							{renderSwatches()}
						</div>
					</div>
				</div>
			)}

			{paramsPath.length > 0 && (
				<div className="endpoint__params endpoint__section">
					<Typography component="h4">Path Parameters</Typography>
					<Params params={paramsPath} />
				</div>
			)}

			{paramsQuery.length > 0 && (
				<div className="endpoint__params endpoint__section">
					<Typography component="h4">Query Parameters</Typography>
					<Params params={paramsQuery} />
				</div>
			)}
		</div>
	);
};

export default Endpoint;
