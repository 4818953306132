import Box from 'components/ui/Box/Box';
import Typography from 'components/ui/Typography/Typography';

// icons
import { ReactComponent as IconAnalogous } from 'icons/analogous.svg';
import { ReactComponent as IconComplementary } from 'icons/complementary.svg';
import { ReactComponent as IconMonochromatic } from 'icons/monochromatic.svg';
import { ReactComponent as IconTetradic } from 'icons/tetradic.svg';
import { ReactComponent as IconTriadic } from 'icons/triadic.svg';

// styles
import './Sidebar.css';

const Sidebar = () => {
	return (
		<div className="sidebar">
			<div className="sidebar__section">
				<Typography classes={['h3']} component="h2">
					Documentation
				</Typography>
			</div>

			<div className="sidebar__section">
				<Box>
					<Typography>
						Palett is a very simple color API used to convert colors into various formats and
						generate color schemes.
					</Typography>
				</Box>
			</div>

			<div className="sidebar__section">
				<ul className="sidebar__list">
					<li>
						<Typography classes={['h4']} component="a" href="#intro">
							Introduction
						</Typography>
						<ul>
							<li>
								<Typography component="a" href="#url">
									URL
								</Typography>
							</li>
							<li>
								<Typography component="a" href="#params">
									Path Parameters
								</Typography>
							</li>
						</ul>
					</li>
					<li>
						<Typography classes={['h4']} component="a" href="#utilities">
							Utilities
						</Typography>
						<ul>
							<li>
								<Typography component="a" href="#convert">
									Convert
								</Typography>
							</li>
							<li>
								<Typography component="a" href="#darken">
									Darken
								</Typography>
							</li>
							<li>
								<Typography component="a" href="#lighten">
									Lighten
								</Typography>
							</li>
							<li>
								<Typography component="a" href="#saturate">
									Saturate
								</Typography>
							</li>
							<li>
								<Typography component="a" href="#desaturate">
									Desaturate
								</Typography>
							</li>
						</ul>
					</li>
					<li>
						<Typography classes={['h4']} component="a" href="#schemes">
							Schemes
						</Typography>
						<ul>
							<li>
								<div>
									<i>
										<IconAnalogous />
									</i>
									<Typography component="a" href="#analogous">
										Analogous
									</Typography>
								</div>
							</li>
							<li>
								<div>
									<i>
										<IconComplementary />
									</i>
									<Typography component="a" href="#complementary">
										Complementary
									</Typography>
								</div>
							</li>
							<li>
								<div>
									<i>
										<IconMonochromatic />
									</i>
									<Typography component="a" href="#monochromatic">
										Monochromatic
									</Typography>
								</div>
							</li>
							<li>
								<div>
									<i>
										<IconTetradic />
									</i>
									<Typography component="a" href="#tetradic">
										Tetradic
									</Typography>
								</div>
							</li>
							<li>
								<div>
									<i>
										<IconTriadic />
									</i>
									<Typography component="a" href="#triadic">
										Triadic
									</Typography>
								</div>
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
