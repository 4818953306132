import React from 'react';

// layout
import Section from 'components/layout/Section/Section';
import Wrap from 'components/layout/Wrap/Wrap';

// page
import Hero from 'components/pages/Home/Hero/Hero';
import Sidebar from 'components/pages/Home/Sidebar/Sidebar';

// ui
import Endpoint from 'components/ui/Endpoint/Endpoint';
import Params from 'components/ui/Params/Params';
import Typography from 'components/ui/Typography/Typography';
import UrlBar from 'components/ui/UrlBar/UrlBar';

// utils
import { cleanHtml } from 'utils';

// styles
import './Home.css';

function Home() {
	const endpointBtns = [
		{
			text: 'CMYK',
			params: {
				model: 'cmyk',
				value: '0,20,100,0',
			},
		},
		{
			text: 'Hex',
			params: {
				model: 'hex',
				value: 'ffcc00',
			},
		},
		{
			text: 'HSL',
			params: {
				model: 'hsl',
				value: '48,100,50',
			},
		},
		{
			text: 'HSV',
			params: {
				model: 'hsv',
				value: '48,100,100',
			},
		},
		{
			text: 'RGB',
			params: {
				model: 'rgb',
				value: '255,204,0',
			},
		},
	];

	const endpointBtnsSaturate = [
		{
			text: 'CMYK',
			params: {
				model: 'cmyk',
				value: '0,13,66,25',
				percentage: 50,
			},
		},
		{
			text: 'Hex',
			params: {
				model: 'hex',
				value: 'bfa640',
				percentage: 50,
			},
		},
		{
			text: 'HSL',
			params: {
				model: 'hsl',
				value: '48,49.8,50',
				percentage: 50,
			},
		},
		{
			text: 'HSV',
			params: {
				model: 'hsv',
				value: '48,66.49,74.9',
				percentage: 50,
			},
		},
		{
			text: 'RGB',
			params: {
				model: 'rgb',
				value: '191,166,64',
				percentage: 50,
			},
		},
	];

	const endpointBtnsPercentage = endpointBtns.map((endpoint) => {
		return {
			...endpoint,
			params: {
				...endpoint.params,
				percentage: 50,
			},
		};
	});

	const paramsPathScheme = [
		{
			description: cleanHtml(
				`A string defined as <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong>.`
			),
			param: 'model',
			required: true,
		},
		{
			description: 'A string (if model is "hex") or comma separated list of numerical values.',
			param: 'value',
			required: true,
		},
	];

	const paramsPathPercentageScheme = paramsPathScheme.concat({
		description: 'A numerical value defining the percentage of change.',
		param: 'percentage',
		required: true,
	});

	const paramsQueryScheme = [
		{
			description: 'A comma separated list of color models (cmyk, hex, hsl, hsv, rgb).',
			param: 'models',
			required: false,
		},
	];

	return (
		<Section classes={['home']} mods={['seamless', 'indigo-800']}>
			<Hero />
			<Section classes={['home__docs']}>
				<Wrap>
					<div className="docs__sidebar">
						<Sidebar />
					</div>

					<div className="docs__main">
						<div id="intro">
							<Typography classes={['main__title']} component="h2">
								Introduction
							</Typography>

							<div className="main__subsection" id="url">
								<Typography>
									The Palett API is a free API used to convert colors into varous formats like RGB,
									HSL and CMYK. It uses a base URL for all of it's endpoints and returns data in
									JSON.
								</Typography>
								<UrlBar
									classes={['main__urlbar']}
									hasCopyPaste={true}
									path={process.env.REACT_APP_API_URL}
									type="URL"
									url={process.env.REACT_APP_API_URL}
								/>
							</div>

							<div className="main__subsection" id="params">
								<Typography classes={['main__subtitle']} component="h3">
									Path Parameters
								</Typography>

								<UrlBar classes={['main__urlbar']} path={`.../:model/:value`} type="PATH" />

								<div className="subsection__section" id="paramsColor">
									<Typography>
										All endpoints that require a color input require both a{' '}
										<Typography component="strong">model</Typography> and{' '}
										<Typography component="strong">value</Typography>.
									</Typography>

									<Params params={paramsPathScheme} />
								</div>
							</div>
						</div>

						<div id="utilities">
							<Typography classes={['main__title']} component="h2">
								Utilities
							</Typography>

							<div className="main__subsection">
								<Endpoint
									id="convert"
									intro="Convert a color from one color model to different color models."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'A color object containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'color',
											type: 'Object',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/utils/convert/:model/:value"
									title="Convert"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/utils/convert`}
									response={{
										success: true,
										color: {
											cmyk: { c: 0, m: 20, y: 100, k: 0 },
											hex: '#ffcc00',
											hsl: { h: 48, s: 100, l: 50 },
											hsv: { h: 48, s: 100, v: 100 },
											rgb: { r: 255, g: 204, b: 0 },
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="darken"
									intro="Darken a color."
									buttons={endpointBtnsPercentage}
									paramsPath={paramsPathPercentageScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'A color object containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'color',
											type: 'Object',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path={`/utils/darken/:model/:value`}
									title="Darken"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/utils/darken`}
									response={{
										success: true,
										color: {
											cmyk: { c: 0, m: 20, y: 100, k: 50 },
											hex: '#806600',
											hsl: { h: 48, s: 100, l: 25.1 },
											hsv: { h: 48, s: 100, v: 50.2 },
											rgb: { r: 128, g: 102, b: 0 },
											name: 'Yukon Gold',
										},
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="lighten"
									intro="Lighten a color."
									buttons={endpointBtnsPercentage}
									paramsPath={paramsPathPercentageScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'A color object containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'color',
											type: 'Object',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/utils/lighten/:model/:value"
									title="Lighten"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/utils/lighten`}
									response={{
										success: true,
										color: {
											cmyk: { c: 0, m: 10, y: 50, k: 0 },
											hex: '#ffe680',
											hsl: { h: 48, s: 100, l: 75.1 },
											hsv: { h: 48, s: 49.8, v: 100 },
											rgb: { r: 255, g: 230, b: 128 },
											name: 'Kournikova',
										},
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="saturate"
									intro="Saturate a color."
									buttons={endpointBtnsSaturate}
									paramsPath={paramsPathPercentageScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'A color object containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'color',
											type: 'Object',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/utils/saturate/:model/:value"
									title="Saturate"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/utils/saturate`}
									response={{
										success: true,
										color: {
											cmyk: { c: 0, m: 17, y: 86, k: 13 },
											hex: '#dfb920',
											hsl: { h: 48, s: 74.9, l: 50 },
											hsv: { h: 48, s: 85.65, v: 87.45 },
											rgb: { r: 223, g: 185, b: 32 },
											name: 'Gold Tips',
										},
									}}
									example={{
										input: {
											hex: '#bfa640',
											name: 'Roti',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="desaturate"
									intro="Desaturate a color."
									buttons={endpointBtnsPercentage}
									paramsPath={paramsPathPercentageScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'A color object containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'color',
											type: 'Object',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/utils/desaturate/:model/:value"
									title="Desaturate"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/utils/desaturate`}
									response={{
										success: true,
										color: {
											cmyk: { c: 0, m: 13, y: 66, k: 25 },
											hex: '#bfa640',
											hsl: { h: 48, s: 49.8, l: 50 },
											hsv: { h: 48, s: 66.49, v: 74.9 },
											rgb: { r: 191, g: 166, b: 64 },
											name: 'Roti',
										},
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>
						</div>

						<div id="schemes">
							<Typography classes={['main__title']} component="h2">
								Schemes
							</Typography>

							<div className="main__subsection">
								<Endpoint
									id="analogous"
									intro="Analogous colors are groups of three colors that are next to each other on the color wheel (generally 30deg apart)."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'An array of three (3) <a href="#paramsColor">color</a> objects containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'colors',
											type: 'Array',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/schemes/analogous/:model/:value"
									title="Analogous"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/schemes/analogous`}
									response={{
										success: true,
										colors: [
											{
												cmyk: { c: 0, m: 70, y: 100, k: 0 },
												hex: '#ff4d00',
												hsl: { h: 18, s: 100, l: 50 },
												hsv: { h: 18, s: 100, v: 100 },
												rgb: { r: 255, g: 77, b: 0 },
												name: 'Vermilion',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 0 },
												hex: '#ffcc00',
												hsl: { h: 48, s: 100, l: 50 },
												hsv: { h: 48, s: 100, v: 100 },
												rgb: { r: 255, g: 204, b: 0 },
												name: 'Supernova',
											},
											{
												cmyk: { c: 30, m: 0, y: 100, k: 0 },
												hex: '#b3ff00',
												hsl: { h: 78, s: 100, l: 50 },
												hsv: { h: 78, s: 100, v: 100 },
												rgb: { r: 179, g: 255, b: 0 },
												name: 'Lime',
											},
										],
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="complementary"
									intro="Complementary colors are two colors that are opposite one another (180deg apart) on the color wheel."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'An array of two (2) <a href="#paramsColor">color</a> objects containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'colors',
											type: 'Array',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/schemes/complementary/:model/:value"
									title="Complementary"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/schemes/complementary`}
									response={{
										success: true,
										colors: [
											{
												cmyk: { c: 0, m: 20, y: 100, k: 0 },
												hex: '#ffcc00',
												hsl: { h: 48, s: 100, l: 50 },
												hsv: { h: 48, s: 100, v: 100 },
												rgb: { r: 255, g: 204, b: 0 },
												name: 'Supernova',
											},
											{
												cmyk: { c: 100, m: 80, y: 0, k: 0 },
												hex: '#0033ff',
												hsl: { h: 228, s: 100, l: 50 },
												hsv: { h: 228, s: 100, v: 100 },
												rgb: { r: 0, g: 51, b: 255 },
												name: 'Blue',
											},
										],
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="monochromatic"
									intro="Monochromatic colors are all colors that share the same hue."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'An array of ten (10) <a href="#paramsColor">color</a> objects containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'colors',
											type: 'Array',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/schemes/monochromatic/:model/:value"
									title="Monochromatic"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/schemes/monochromatic`}
									response={{
										success: true,
										colors: [
											{
												cmyk: { c: 0, m: 4, y: 20, k: 0 },
												hex: '#fff5cc',
												hsl: { h: 48, s: 100, l: 90 },
												hsv: { h: 48, s: 20, v: 100 },
												rgb: { r: 255, g: 245, b: 204 },
												name: 'Barley White',
											},
											{
												cmyk: { c: 0, m: 8, y: 40, k: 0 },
												hex: '#ffeb99',
												hsl: { h: 48, s: 100, l: 80 },
												hsv: { h: 48, s: 40, v: 100 },
												rgb: { r: 255, g: 235, b: 153 },
												name: 'Vis Vis',
											},
											{
												cmyk: { c: 0, m: 12, y: 60, k: 0 },
												hex: '#ffe066',
												hsl: { h: 48, s: 100, l: 70 },
												hsv: { h: 48, s: 60, v: 100 },
												rgb: { r: 255, g: 224, b: 102 },
												name: 'Goldenrod',
											},
											{
												cmyk: { c: 0, m: 16, y: 80, k: 0 },
												hex: '#ffd633',
												hsl: { h: 48, s: 100, l: 60 },
												hsv: { h: 48, s: 80, v: 100 },
												rgb: { r: 255, g: 214, b: 51 },
												name: 'Bright Sun',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 0 },
												hex: '#ffcc00',
												hsl: { h: 48, s: 100, l: 50 },
												hsv: { h: 48, s: 100, v: 100 },
												rgb: { r: 255, g: 204, b: 0 },
												name: 'Supernova',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 20 },
												hex: '#cca300',
												hsl: { h: 48, s: 100, l: 40 },
												hsv: { h: 48, s: 100, v: 80 },
												rgb: { r: 204, g: 163, b: 0 },
												name: 'Buddha Gold',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 30 },
												hex: '#b38f00',
												hsl: { h: 48, s: 100, l: 35.1 },
												hsv: { h: 48, s: 100, v: 70.2 },
												rgb: { r: 179, g: 143, b: 0 },
												name: 'Pirate Gold',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 40 },
												hex: '#997a00',
												hsl: { h: 48, s: 100, l: 30 },
												hsv: { h: 48, s: 100, v: 60 },
												rgb: { r: 153, g: 122, b: 0 },
												name: 'Buttered Rum',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 50 },
												hex: '#806600',
												hsl: { h: 48, s: 100, l: 25.1 },
												hsv: { h: 48, s: 100, v: 50.2 },
												rgb: { r: 128, g: 102, b: 0 },
												name: 'Yukon Gold',
											},
											{
												cmyk: { c: 0, m: 20, y: 100, k: 60 },
												hex: '#665200',
												hsl: { h: 48, s: 100, l: 20 },
												hsv: { h: 48, s: 100, v: 40 },
												rgb: { r: 102, g: 82, b: 0 },
												name: 'Antique Bronze',
											},
										],
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="tetradic"
									intro="Tetradic are four colors equally spaced out that form a square on the color wheel (generally 0deg, 90deg, 180deg and 270deg apart)."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'An array of four (4) <a href="#paramsColor">color</a> objects containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'colors',
											type: 'Array',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/schemes/tetradic/:model/:value"
									title="Tetradic"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/schemes/tetradic`}
									response={{
										success: true,
										colors: [
											{
												cmyk: { c: 0, m: 20, y: 100, k: 0 },
												hex: '#ffcc00',
												hsl: { h: 48, s: 100, l: 50 },
												hsv: { h: 48, s: 100, v: 100 },
												rgb: { r: 255, g: 204, b: 0 },
												name: 'Supernova',
											},
											{
												cmyk: { c: 100, m: 0, y: 70, k: 0 },
												hex: '#00ff4c',
												hsl: { h: 138, s: 100, l: 50 },
												hsv: { h: 138, s: 100, v: 100 },
												rgb: { r: 0, g: 255, b: 76 },
												name: 'Malachite',
											},
											{
												cmyk: { c: 100, m: 80, y: 0, k: 0 },
												hex: '#0033ff',
												hsl: { h: 228, s: 100, l: 50 },
												hsv: { h: 228, s: 100, v: 100 },
												rgb: { r: 0, g: 51, b: 255 },
												name: 'Blue',
											},
											{
												cmyk: { c: 0, m: 100, y: 30, k: 0 },
												hex: '#ff00b3',
												hsl: { h: 318, s: 100, l: 50 },
												hsv: { h: 318, s: 100, v: 100 },
												rgb: { r: 255, g: 0, b: 179 },
												name: 'Shocking Pink',
											},
										],
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>

							<div className="main__subsection">
								<Endpoint
									id="triadic"
									intro="Triadic colors are three colors equally spaced across from one another on the color wheel (generally 0deg, 120deg and 240deg apart)."
									buttons={endpointBtns}
									paramsPath={paramsPathScheme}
									paramsResponse={[
										{
											description: cleanHtml(
												'An array of three (3) <a href="#paramsColor">color</a> objects containing one or more of <strong>hex</strong>, <strong>rgb</strong>, <strong>hsv</strong>, <strong>hsl</strong> or <strong>cmyk</strong> properties.'
											),
											param: 'colors',
											type: 'Array',
										},
									]}
									paramsQuery={paramsQueryScheme}
									path="/schemes/triadic/:model/:value"
									title="Triadic"
									type="get"
									url={`${process.env.REACT_APP_API_URL}/schemes/triadic`}
									response={{
										success: true,
										colors: [
											{
												cmyk: { c: 0, m: 20, y: 100, k: 0 },
												hex: '#ffcc00',
												hsl: { h: 48, s: 100, l: 50 },
												hsv: { h: 48, s: 100, v: 100 },
												rgb: { r: 255, g: 204, b: 0 },
												name: 'Supernova',
											},
											{
												cmyk: { c: 100, m: 0, y: 20, k: 0 },
												hex: '#00ffcc',
												hsl: { h: 168, s: 100, l: 50 },
												hsv: { h: 168, s: 100, v: 100 },
												rgb: { r: 0, g: 255, b: 204 },
												name: 'Bright Turquoise',
											},
											{
												cmyk: { c: 20, m: 100, y: 0, k: 0 },
												hex: '#cc00ff',
												hsl: { h: 288, s: 100, l: 50 },
												hsv: { h: 288, s: 100, v: 100 },
												rgb: { r: 204, g: 0, b: 255 },
												name: 'Magenta / Fuchsia',
											},
										],
									}}
									example={{
										input: {
											hex: '#ffcc00',
											name: 'Supernova',
										},
									}}
								/>
							</div>
						</div>
					</div>
				</Wrap>
			</Section>
		</Section>
	);
}

export default Home;
