import React, { useState } from 'react';

// ui
import Button from 'components/ui/Button/Button';
import Notification from 'components/ui/Notification/Notification';
import Typography from 'components/ui/Typography/Typography';

// icons
import IconFileCopy from '@material-ui/icons/FileCopy';

// styles
import './UrlBar.css';

const UrlBar = ({ classes, hasCopyPaste, mods, path, type, url }) => {
	// vars
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames = mods ? ` ${mods.map((mod) => `btn--${mod}`).join(' ')}` : '';
	const urlBarProps = {};

	let Tag = 'div';

	// state
	const [isCopied, setIsCopied] = useState(false);

	const handleCopyPaste = (e) => {
		e.preventDefault();
		// copy url to clipboard
		navigator.clipboard.writeText(url);
		setIsCopied(true);

		setTimeout(() => {
			setIsCopied(false);
		}, 3000);
	};

	if (url) {
		Tag = 'a';
		urlBarProps.href = url;
		urlBarProps.target = '_blank';
		urlBarProps.rel = 'noreferrer';
	}

	return (
		<>
			<Tag className={`urlbar${classNames}${modNames}`} {...urlBarProps}>
				{type && (
					<Typography classes={['urlbar__type']} component="span">
						{type}
					</Typography>
				)}
				<Typography classes={['urlbar__path']} component="span">
					{decodeURIComponent(path)}
				</Typography>
				{hasCopyPaste && (
					<Button
						classes={['urlbar__copypaste']}
						component="div"
						icon={<IconFileCopy />}
						mods={['circle', 'icon']}
						onClick={(e) => handleCopyPaste(e)}
					/>
				)}
			</Tag>
			<Notification
				isActive={isCopied}
				mods={['top', 'left']}
				modsAlert={['success']}
				text="Copied"
			/>
		</>
	);
};

UrlBar.defaultProps = {
	hasCopyPaste: false,
};

export default UrlBar;
