import React from 'react';

// ui
import Alert from 'components/ui/Alert/Alert';
import Typography from 'components/ui/Typography/Typography';

// styles
import './Notification.css';

const Notification = ({ children, classes, icon, isActive, mods, modsAlert, text, ...other }) => {
	const classNames = classes ? ` ${classes.join(' ')}` : '';
	const modNames = mods ? ` ${mods.map((mod) => `notification--${mod}`).join(' ')}` : '';
	const notificationProps = {
		className: `notification${classNames}${modNames}`,
	};

	const renderText = () => {
		if (children) {
			return children;
		}

		return <Typography>{text}</Typography>;
	};

	if (isActive) {
		notificationProps.className += ` notification--active`;
	}

	return (
		<div {...notificationProps}>
			<Alert icon={icon} mods={modsAlert} text={renderText()} />
		</div>
	);
};

export default Notification;
