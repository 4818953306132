// sanitize HTML
export const createMarkup = (content) => {
	return {
		__html: content,
	};
};

// html
export const cleanHtml = (content, el, classes) => {
	const Tag = el ? el : 'p';
	const opts = {};

	// if classes were passed
	if (classes) {
		let elClasses = '';

		// loop through classes and construct string
		for (let i = 0; i < classes.length; i++) {
			elClasses += `${classes[i]} `;
		}

		// add class attribute to options object
		opts['className'] = elClasses;
	}

	return <Tag {...opts} dangerouslySetInnerHTML={createMarkup(content)} />;
};
