// styles
import './Swatch.css';

const Swatch = ({ hex }) => {
	const swatchProps = {};

	if (hex) {
		swatchProps.style = { backgroundColor: hex };
	}
	return <div className="swatch" {...swatchProps}></div>;
};

export default Swatch;
